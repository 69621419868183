import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const FeatureContent2 = [
  {
    icon: "23",
    title: "Diverse Betting",
    subTitle: `Diverse Betting Options: Uniment offers a wide range of betting opportunities across various sports and events, ensuring users can find something that suits their preferences with highly competitive odds.`,
    hoverText: `Bring all team slit managment task quis togather.`,
    altName: "Screen 1",

  },
  {
    icon: "17",
    title: "Affiliate System",
    subTitle: `Affiliate & Agent System: Our affiliate and agent system offers partners lucrative opportunities to grow their business, with competitive commissions, advanced tracking, and personalized support for mutual success.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "14",
    title: "Reporting System",
    subTitle: `Flexible Reporting System: Our platform features a flexible reporting system, allowing users to easily generate and customize reports based on their specific needs, providing valuable insights for informed decision-making.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "85",
    title: "Advanced Bonus Engine",
    subTitle: `Our powerful bonus engine allows for complete customization, offering both wagerable and real-balance bonuses. Create tailored promotions to suit your needs and enhance your betting experience.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "86",
    title: "Expert Insights",
    subTitle: `Our team provides in-depth analysis, betting trends, and tailored insights, helping B2B partners optimize markets and boost player engagement for long-term success in the competitive gambling industry.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
];

const FeatureContent = () => {
  const settings = {
    dots: false,
    draggable: false,
    arrow: true,
    infinite: false,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],

  };

  return (
    <>
      <Slider {...settings}>
        {FeatureContent2.map((val, i) => (
          <div className="item" key={i}>
            <div className="block-style-seventeen extra-margin">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src={`images/icon/${val.icon}.svg`} alt="icon" />
              </div>
              <div className="static-text">
                <h3>{val.title}</h3>
                <p>{val.subTitle}</p>
              </div>
              <div className="hover-text">{val.hoverText}</div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default FeatureContent;
