import React from "react";

const FeatureContent = [
  {
    icon: "78",
    title: "Easy Customizable",
    subTitle: `With our user-friendly interface and intuitive tools, you can easily customize betting options and odds. Whether you’re launching your own betting site or creating a personalized experience for your community, our platform empowers you to get started and enjoy online betting right away.`,
    hoverText: `Our platform is fully operational and ready for you to start enjoying the excitement of online betting and gaming right away.`,
  },
  {
    icon: "79",
    title: "Modern & Slick",
    subTitle: `Step into the future of online gaming with Uniment. Our sleek platform combines modern design and cutting-edge technology, offering a seamless experience whether you're spinning slots, playing table games,or engaging interface that enhances every moment of play.`,
    hoverText: `Our platform offers a seamless experience, whether you're spinning top-rated slots, testing your skills at the tables, or enjoying live dealer games.`,
  },
  {
    icon: "80",
    title: "Trusted & Secure",
    subTitle: `Rest assured, our sports betting section is equipped with top-tier security, ensuring your peace of mind while betting on your favorite sports. With our trusted platform, your personal information and transactions are always safeguarded, allowing you to focus on the thrill of the game.`,
    hoverText: `Our sports betting section offers robust security, keeping your information and transactions safe. Bet with confidence and enjoy the excitement of the game, knowing you're protected.`,
  },
];

const FancyFeature = () => {
  return (
    <div className="row justify-content-center pt-50 md-pt-20">
      {FeatureContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="block-style-seventeen mt-40">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="static-text">
              <h3>{val.title}</h3>
              <p>{val.subTitle}</p>
            </div>
            <div className="hover-text">{val.hoverText}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FancyFeature;
