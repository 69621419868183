import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const HeroBannerSix = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <h1
            className="font-recoleta hero-heading"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Sportsbetting and <span>iGaming</span> solutions provider.
          </h1>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="FDaF8_5dzzk"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default HeroBannerSix;
